<!-- 优惠券管理 -->
<template>
	<div>
		<div style="width: 100%;background: #fff;height: 120px;border-style:solid;border-width:1px;border-color:#e7e7e7;" class="my-3">
			<div class="ml-3 my-3 d-flex">
				<div style="font-weight: 800;">抽奖设置:</div>
				<div style="margin-left: 10px;"><el-button type="primary" @click="onSubmit" size="mini">保存设置</el-button></div>
			</div>
			<div style="align-items: center;justify-content: center;" class="d-flex">
				<div class="nr" style="width: 30%;">
					<div class="nr1">消耗积分:</div>
					<div class="nr2"><el-input v-model="form.jf" placeholder="请输入内容" size="mini"></el-input></div>
				</div>
				<div class="nr" style="width: 30%;">
					<div class="nr1 mr-2">游戏难度:</div>
					<div class="nr2"><el-input v-model="form.nd" placeholder="请输入内容" size="mini"></el-input></div>
				</div>
				<div class="nr" style="width: 40%;">
					<div class="nr1">活动时间:</div>
					<div class="nr2">
						<el-date-picker
							v-model="value"
							type="datetimerange"
							format="yyyy-MM-dd HH:mm:ss"
							value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							size="mini"
						></el-date-picker>
					</div>
				</div>
			</div>
		</div>

		<div style="width: 100%;background: #fff;height: 380px;border-style:solid;border-width:1px;border-color:#e7e7e7;">
			<div class="ml-3 my-3 d-flex">
				<div style="font-weight: 800;">奖品设置:</div>
				<div style="margin-left: 10px;"><el-button type="primary" @click="submit" size="mini">保存设置</el-button></div>
			</div>
			<div class="d-flex">
				<div style="width: 40%;">
					<div style="display: flex;justify-content: center;">
						<div class="jp m-3">奖品1</div>
						<div class="jp m-3">奖品2</div>
						<div class="jp m-3">奖品3</div>
					</div>
					<div style="display: flex;justify-content: center;">
						<div class="jp m-3">奖品4</div>
						<div class="m-3" style="width: 60px;height: 60px;"></div>
						<div class="jp m-3">奖品8</div>
					</div>
					<div style="display: flex;justify-content: center;">
						<div class="jp m-3">奖品5</div>
						<div class="jp m-3">奖品6</div>
						<div class="jp m-3">奖品7</div>
					</div>
					<div style="display: flex;justify-content: center;">
						<div class="f10" style="color: #ff0000;">中奖公式：奖品数量/奖品总数量 = 中奖概率</div>
					</div>
				</div>
				<div style="width: 60%;">
					<div class="d-flex mb-2" style="align-items: center;" v-for="(e,i) of cjwp" :key="i">
						<div class="f10 mr-2">奖品{{i+1}}：</div>
						<div class="mr-2">
							<el-select v-model="e.lx" placeholder="请选择" size="mini" >
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="mr-2" v-if="e.lx == 1">
							<el-select v-model="e.lxid" placeholder="请选择实物奖品" size="mini">
								<el-option v-for="item in optionsa" :key="item.id" :label="item.title" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="mr-2" v-else-if="e.lx == 2">
							<el-select v-model="e.lxid" placeholder="请选择优惠券" size="mini">
								<el-option v-for="item in optionsb" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="mr-2" v-else style="width:180px;">
							<el-input v-model="e.lxid" placeholder="请输入积分" size="mini"></el-input>
						</div>
						<div class="f10 mr-2 ml-2">数量：</div>
						<div class="mr-2">
							<el-input-number v-model="e.num" :min="0" label="请输入数量" size="mini"></el-input-number>
						</div>
						<div class="f10 mr-2 ml-2" style="color: #ff0000;">当前中奖概率：{{e.gl}}%</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'; // 引入 全局 公共数据
export default {
	inject: ['app', 'layout'], // 注入layout.vue
	data() {
		return {
			form: {
				jf: 0,
				nd: 0,
				ks: '',
				js: ''
			},
			input: '',
			value: [],
			
			options: [
				{
					value: 1,
					label: '实物'
				},
				{
					value: 2,
					label: '优惠券'
				},
				{
					value: 3,
					label: '积分'
				},
			],
			optionsa: [],
			optionsb: [],
			cjwp:[],
			nums:0
		};
	},
	created() {
		this.getlist();
	},
	computed: {
		...mapState({
			user: state => state.user.user
		})
	},

	methods: {
		getlist() {
			this.axios.get('/admin/cjsz/1', {
				token: true
			}).then(res => {
				this.form = res.data.data;
				this.value1 = [res.data.data.ks, res.data.data.js];
			});
			this.axios.get('/admin/goods/data', {
				token: true
			}).then(res => {
				this.optionsa = res.data.data
			});
			this.axios.get('/admin/coupon/data', {
				token: true
			}).then(res => {
				this.optionsb = res.data.data
			});
			this.axios.get('/admin/cjwp/1', {
				token: true
			}).then(res => {
				let list = res.data.data.list
				let nums = res.data.data.nums
				this.cjwp = list.map(item =>{
					let nus = item.num/nums*100
					return{
						id:item.id,
						lx:item.lx,
						lxid:item.lxid,
						num:item.num,
						gl:nus.toFixed(3)
					}
				})
			});
		},
		onSubmit() {
			let data = this.form;
			data.ks = this.value[0];
			data.js = this.value[1];
			this.axios
				.post('/admin/cjsz/1', data, {
					token: true
				})
				.then(res => {
					this.$message({
						type: 'success',
						message: '操作成功'
					});
				});
		},
		submit() {
			let data = this.cjwp;
			// data.ks = this.value[0];
			// data.js = this.value[1];
			
			this.axios
				.post('/admin/upcjwp', data, {
					token: true
				})
				.then(res => {
					this.$message({
						type: 'success',
						message: '操作成功'
					});
				});
		}
	}
};
</script>

<style>
.nr {
	height: 40px;
	margin-left: 1%;
	margin-right: 1%;
	display: flex;
}
.nr1 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	width: 25%;
}
.nr2 {
	display: flex;
	align-items: center;
	font-size: 14px;
	width: 100%;
}
.jp {
	border-style: solid;
	border-width: 1px;
	border-color: #e7e7e7;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
}
.f10 {
	font-size: 10px;
}
</style>
